<template>
  <div class="special-filter-wrapper">
    <div class="special-filter-card">
      <div class="special-filter-list top">
        <div class="special-filter-list-left">
          <!-- tab -->
          <div
            :class="'filter-job-cate ' + (curIndex === i ? 'active' : '')"
            v-for="(l, i) in selectKeys"
            :key="i"
            @click="toggleCate(i)"
            v-text="l.label"
          />
        </div>
        <!-- 搜索 -->
        <div class="search-box">
          <el-input
            placeholder="请输入内容"
            class="search-input"
            prefix-icon="el-icon-search"
            @change="searched"
            v-model="search_content"
            size="small"
          >
          </el-input>
        </div>
      </div>
      <div class="qz-city-filter-list">
        <span>地区：</span>
        <div class="qz-city-filter-list-wrapper">
          <div
            v-for="(l,i) in qzArea"
            :key="l.label"
            @click="toggleArea(i)"
            :class="'qz-city-item ' + (curAreaIndex === i ? 'active' : '')"
          >
            {{ l.label }}
          </div>
        </div>
      </div>

      <!-- 底部 下拉筛选部分 -->
      <div class="special-filter-list" v-if="curIndex===0" >
        <div class="special-filter-list-left">
          <!-- 工作经验 -->
          <div class="special-filter-item">
            <el-dropdown trigger="click" @command="handleCommand($event, 'experiences')">
              <span class="el-dropdown-link">
                工作经验：<span class="drop-title">
                  {{ experiences || '不限' }}
                </span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="l in experienceList"
                  :command="l.value"
                  :key="l.label"
                  v-text="l.label"
                />
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <!-- 学历 -->
          <div class="special-filter-item">
            <el-dropdown trigger="click" @command="handleCommand($event, 'educations')">
              <span class="el-dropdown-link">
                学历要求：<span class="drop-title">
                  {{ educations || '不限' }}
                </span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="l in educationList"
                  :command="l.value"
                  :key="l.label"
                  v-text="l.label"
                />
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <!-- 薪资 -->
          <div class="special-filter-item">
            <el-dropdown
              trigger="click"
              class="dropdown-box"
              @command="handleCommand($event, 'salary_range')"
            >
              <span class="el-dropdown-link">
                薪资要求：<span class="drop-title">
                  {{ salary_range || '不限' }}
                </span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="l in salaryList"
                  :command="l.value"
                  :key="l.label"
                  v-text="l.label"
                />
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <!-- 级联筛选 行业-->
          <div class="special-filter-item">
            <span>行业：</span>
            <el-cascader
              class="industry-style"
              v-model="industry"
              :options="category"
              @change="industryChange"
              clearable
              separator="-"
            >
            </el-cascader>
          </div>
        </div>

        <div class="filter-clear" @click="clear">清空筛选条件</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  experience,
  salary,
  education,
  specialCfCate,
  category,
  qzArea,
} from 'constants/publicData';
export default {
  name: 'JobsFilter',
  props: {
    initData: Object,
  },
  data() {
    return {
      curIndex: 0,
      experiences: '',
      educations: '',
      salary_range: '',
      industry: [],
      search_content: '',
      city: '',
      recent: null,
      selectItmes: {},
      back: {},
      curAreaIndex: 0,
    };
  },
  created() {
    if (this.initData) {
      this.init(this.initData);
    }
    // console.log(this.qzArea);
  },
  methods: {
    init(initData) {
      const {
        curIndex,
        experiences,
        educations,
        salary_range,
        search_content,
        industry,
        city
      } = initData;

      if (
        curIndex !== 0 &&
        curIndex !== undefined &&
        curIndex !== null &&
        curIndex !== '' &&
        !isNaN(curIndex)
      ) {
        if (typeof curIndex == 'number') this.curIndex = curIndex || 0;
        else console.error('initData.curIndex error');
      }
      if (experiences) {
        if (experience.some(item => item === experience)) this.experiences = experiences || '';
        else console.error('initData.experience error');
      }

      if (educations) {
        if (education.some(item => item === educations)) this.educations = educations || '';
        else console.error('initData.education error');
      }

      if (salary_range) {
        if (salary.some(item => item === salary_range)) this.salary_range = salary_range || '';
        else console.error('initData.curIndex error');
      }
      if (city) {
        if (qzArea.some(item => item === city)) this.city = city || '';
        else console.error('initData.city error');
      }

      if (search_content) {
        this.search_content = search_content || '';
      }

      if (industry) {
        this.industry = industry.split('-') || [];
      }

      // specialCfCate.map(item => {
      //   if (initData[item.value]) {
      //     this.selectItmes[item.value] = initData[item.value];
      //   }
      // });
    },
    toggleCate(i) {
      this.curIndex = i;
      console.log(i)
      // if (Object.keys(this.selectItmes).length) {
      //   for (let key in this.selectItmes) {
      //     this.selectItmes[key] = '';
      //   }
      // }

      // if (specialCfCate[i] !== -1 && specialCfCate[i].value) {
      //   this.selectItmes[specialCfCate[i].value] = true;
      // } else if (specialCfCate[i].value) {
      //   this.selectItmes[specialCfCate[i].value] = '';
      // }
      // this.callBack();
      this.clear()
    },
    toggleArea(i) {
      this.curAreaIndex = i;
      this.city = qzArea[i]?.value||''
      this.callBack();

    },
    handleCommand(command, name) {
      this[name] = command;
      this.callBack();
    },
    industryChange() {
      this.callBack();
    },
    searched(val) {
      this.search_content = val;
      this.callBack();
    },
    clear() {
      this.experiences = '';
      this.educations = '';
      this.salary_range = '';
      this.industry = [];
      this.city = '';
      this.curAreaIndex= 0;
      this.callBack();
    },
    callBack() {
      // console.log(this.selectItmes);
      let data = {
        curIndex: this.curIndex,
        experiences: this.experiences,
        educations: this.educations,
        salary_range: this.salary_range,
        search_content: this.search_content,
        city:this.city,
        industry: this.industry ? this.industry.join('-') : '',
        ...this.selectItmes,
      };
      // console.log(data);
      this.$emit('change', data);
    },
  },
  computed: {
    selectKeys: () => specialCfCate,
    experienceList: () => experience,
    salaryList: () => salary,
    educationList: () => education,
    category: () => category,
    qzArea: () => qzArea,
  },
  watch: {
    selectItmes() {
      // console.log(this.selectItmes)
    },
  },
};
</script>
<style lang="scss">
@import 'constants/style/public';
.special-filter-card {
  background-color: $defaultBg;
  // margin-top: 26px;
  padding: 15px 10px;
  box-shadow: 0 0 18px $shadowColor;
  // border-radius: $borderRadius;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.qz-city-filter-list {
  display: flex;
  align-items: center;
  padding: 20px 13px 0;
  font-size: var(--medium, 14px);
  color: #606266;
  &-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: 32px;
    .qz-city-item {
      cursor: pointer;
      &.active {
        color: $moreColor;
      }
    }
  }
}
.special-filter-list {
  padding: 20px 13px 0;
  display: flex;
  justify-content: space-between;

  &.top {
    padding: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid $lineColor;
  }
  .special-filter-list-left {
    display: flex;
    align-items: center;
    .special-filter-item {
      font-size: var(--medium, 14px);
      margin-right: 80px;

      .drop-title {
        color: $moreColor;
        margin-right: 20px;
      }
      .el-dropdown-link {
        cursor: pointer;
      }
      .industry-style {
        &.el-cascader {
          line-height: 1;
        }
        .el-input__suffix {
          color: #606266;
        }
        > .el-input {
          .el-input__inner {
            &::placeholder {
              color: var(--moreColor, #666);
            }
            height: auto;
            line-height: 1;
            border: 0;
            color: var(--moreColor, #666);
          }
          .el-input__icon {
            line-height: 1;
          }
        }
      }
    }
    .filter-job-cate {
      position: relative;
      cursor: pointer;
      margin: 0 13px;
      padding: 7px 23px;
      &.active {
        &::after {
          width: 100%;
          height: 2px;
          content: '';
          position: relative;
          top: 12px;
          background-color: var(--moreColor, #666);
          display: block;
        }
      }
    }
  }
  .search-input {
    .el-input__inner {
      border-radius: $borderRound;
      &:focus {
        border-color: $moreColor;
      }
      // background-color: $searchBg;
    }
  }
  .filter-clear {
    cursor: pointer;
    font-size: $medium;
    color: $moreColor;
  }
}
</style>
